import React, {useState} from 'react'
import {Container, Row, Col} from "react-bootstrap"

// CATEGORIES IMPORT  
import AllCategory from './Products/allproducts'
import ChansCategory from './Products/chanscategory'
import HouseCategory from './Products/housescategory'
import AccessoriesCategory from './Products/acsessories'
import GrilsCategory from './Products/grils'



const ProjectsFilter = () => {
    const [category, setCategory] = useState(AllCategory);

    function handleClick(category) {
        setCategory(category);
    }

    return(
        <>
            <Container fluid>
                <Row style={{ paddingBottom: '1rem'}}>
                    {/* <div className="catalog-prev__filter"> */}
                        <Col xl={2} lg={2} md={2} sm={6} xs={12}>
                            <button className="btn-chan btn-chan--orange catalog-prev__more" onClick={()=> handleClick(AllCategory) } >Вся продукция</button>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={6} xs={12}>
                            <button className="btn-chan btn-chan--orange catalog-prev__more" onClick={()=> handleClick(ChansCategory) } >Банные Чаны</button>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={6} xs={12}>
                            <button className="btn-chan btn-chan--orange catalog-prev__more" onClick={()=> handleClick(AccessoriesCategory) } >Опции</button>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={6} xs={12}>
                            <button className="btn-chan btn-chan--orange catalog-prev__more" onClick={()=> handleClick(HouseCategory) } >Дома и срубы</button>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={6} xs={12}>
                            <button className="btn-chan btn-chan--orange catalog-prev__more" onClick={()=> handleClick(GrilsCategory) } >Грили</button>
                        </Col>
                    {/* </div> */}
                
                </Row>
                <Row>
                    <Col>
                        {category}
                    </Col>
                </Row>
            </Container>    
        </>
        
    )
}

export default ProjectsFilter