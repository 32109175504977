import React from 'react'
import {Container, Row, Col} from "react-bootstrap"
import Layout from "../components/layout"
// import Seo from "../components/seo"


import CatalogPage from "../components/Catalog"


const Catalog = () => {

    return(
        <>
        <Layout>
            <Container>            
                <Row>
                    <Col>
                        <CatalogPage />
                    </Col>
                </Row>
            </Container>
        </Layout>
        </>
    )
}

export default Catalog


