import React from 'react'
import {Container, Row, Col} from "react-bootstrap"
import { useStaticQuery, graphql } from 'gatsby'
import Seo from "../seo"
import ProjectsFilter from "./ProductsFilter"


const CatalogPage = () => {
    const data = useStaticQuery(graphql`
    {
      allWpPage(filter: {slug: {eq: "products"}}) {
        nodes {
            title
            content
            seoqueries {
                title
                description
                keywords
            }
        }
      }
    }
  `)

            //SEO THINGS
        const SeoTitle = data.allWpPage.nodes.map(({seoqueries}) => (seoqueries.title))
        const SeoDescription = data.allWpPage.nodes.map(({seoqueries}) => (seoqueries.description)) 
        const SeoKeywords = data.allWpPage.nodes.map(({seoqueries}) => (seoqueries.keywords))
          //END SEO THINGS

        const prodTitle = data.allWpPage.nodes.map(({title}) => (<p dangerouslySetInnerHTML={{ __html: title }} />))
        // const featureImage = data.allWpPage.nodes.map(({featuredImage}) => (<img src={featuredImage.node.localFile.childImageSharp.fluid.src } alt="Производство банных чанов в Екатеринбурге" />))
        const prodContent = data.allWpPage.nodes.map(({content}) => (<div  dangerouslySetInnerHTML={{ __html: content }} />))

    return(
        <>
            <Seo 
                title = {SeoTitle}
                description = {SeoDescription}
                keywords = {SeoKeywords.toString()}
            />
        <Container fluid className="projects-container">
            <div className="projects-overlay"></div>
            <Row>
                <Col>
                    <p class="section-title-page section-title-page--green">{prodTitle}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    {prodContent}
                </Col>
            </Row>
            <Row>
                <Col className="project-buttons-block">
                    <ProjectsFilter />
                </Col>
            </Row>

        </Container>
        </>
    )
}

export default CatalogPage