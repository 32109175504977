import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import {Container, Row, Col} from "react-bootstrap"


const GrilsCategory = () => {
  const data = useStaticQuery(graphql`
  {
    allWpPost(filter: {categories: {nodes: {elemMatch: {slug: {eq: "grils"}}}}}) {
        nodes {
          productTitles {
            productName
            productSubname
            productimage {
              sourceUrl
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          title
          content
          slug
          catalogDescriptions {
            catalogDescription
          }
        }
      }
  }
  `)
    return (
        <Container>
            <Row>
                {data.allWpPost.nodes.map(({content, slug, productTitles, catalogDescriptions}) => (
                    <Col xl={4}>
                        <div class="catalog-prev">
                            <div class="catalog-prev__header">
                                <span class="catalog-prev__category">{productTitles.productName}</span>
                                <p class="catalog-prev__name"dangerouslySetInnerHTML= {{__html: productTitles.productSubname}} />
                                <div class="catalog-prev__img"><img class="catalog-prev__img-pic" src={productTitles.productimage.localFile.childImageSharp.original.src} alt="" /></div>
                            </div>
                            <div class="catalog-prev__main">
                                <div class="catalog-prev__description" dangerouslySetInnerHTML= {{__html: catalogDescriptions.catalogDescription}} />
                                <Link to={slug} className="btn-chan btn-chan--orange catalog-prev__more">ПОДРОБНЕЕ</Link>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </Container>
)
}

export default GrilsCategory;